import MobileLogo from "assets/logos/youth/stemer_logo_lg.jpeg";
import Mobile2Columns from "assets/logos/youth/stemer_logo_sm.jpeg";
import FormInput from "components/FormInput";
import MobileLayout from "components/layouts/MobileLayout";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";

const ResetPasswordMobile = ({
  handleSubmit,
  isLoading = false,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
}) => {
  return (
    <MobileLayout title="">
      <div className="flex flex-col items-center justify-center gap-3">
        <Link to="/">
          <div className="relative flex items-center justify-center">
            <img
              src={MobileLogo}
              alt="Logo"
              sizes="100%"
              className="w-full max-[420px]:hidden sm:hidden block h-[26px]"
              priority="true"
            />
            <img
              src={Mobile2Columns}
              alt="Logo"
              sizes="100%"
              className="w-full min-[420px]:hidden sm:hidden block h-[36px]"
              priority="true"
            />
          </div>
        </Link>
        <div className="max-w-[80%] w-full text-center text-st-gray-text"></div>
      </div>
      <form
        className="flex flex-col h-full max-w-[540px] mx-auto mt-3"
        onSubmit={handleSubmit}
      >
        <div>
          <div>
            <FormInput
              screen="mobile"
              label="New Password"
              placeholder="Enter your new password"
              value={password}
              required
              type="password"
              invalidMessage="Invalid Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <FormInput
              screen="mobile"
              label="Confirm New Password"
              placeholder="Confirm your new password"
              value={confirmPassword}
              required
              type="password"
              invalidMessage="Passwords do not match"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex-1 flex items-start mt-[32px]">
          <button
            disabled={isLoading}
            className="w-full bg-st-primary text-white text-base font-semibold rounded-lg p-[14px] flex justify-center"
            type="submit"
          >
            {isLoading ? (
              <RotatingLines
                visible={true}
                height="24"
                width="24"
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
              />
            ) : (
              "Change Password"
            )}
          </button>
        </div>
        <div className="mt-7 text-center">
          <p className="text-st-primary text-xs w-max mx-auto">
            <Link to="/login">Back to Login</Link>
          </p>
        </div>
      </form>
    </MobileLayout>
  );
};

export default ResetPasswordMobile;

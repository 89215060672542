/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import axios from "axios";
import MediaQuery from "components/MediaQuery";
import Desktop from "./desktop";
import Mobile from "./mobile";
import { toast } from "react-toastify";

const Register = () => {
  const { id } = useParams();
  const { token, isRegister, errors } = useSelector((state) => state.authStore);

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [isInvalidPhoneNumber] = useState(false);

  useEffect(() => {
    if (isRegister && token) {
      localStorage.setItem("stemmer_token", token);
      window.location.href = "/#/invite";
      window.location.reload();
    }
    if (errors && errors.register) {
      console.log(">> errors: ", errors);
      toast.error(errors.register);
    }
    setIsLoading(false);
  }, [isRegister, errors, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== rePassword) {
      toast.error('"Password" and "Confirm Password" don\'t match.');
      return;
    }

    setIsLoading(true);
    const newData = {
      firstName,
      lastName,
      email,
      userId: email,
      passcode: password,
      phoneNumber,
    };

    if (id) {
      newData.id = id;
    }

    try {
      const res = await axios.post(
        `https://youth.stemmer-markets.ai/${id ? "prospect" : ""}register`,
        newData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "f5d7a1f7-4c13-4d67-9813-ee6d6e76a135",
          },
        }
      );
      console.log("res: ", res.data);
      const data = res.data;
      if (data && data.info && data.info.loginSuccess) {
        const { code, token } = data.info.loginSuccess;

        if (code === 200) {
          localStorage.setItem("stemmer_token", token);
          toast.success("Registration completed successfully.");
          window.location.href = "/#/invite";
          window.location.reload();
        }

        if (code === 409) {
          toast.error(
            "User already exists. Please log in or register a new user."
          );
        }
      }
    } catch (err) {
      console.log(">>> error: ", err);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <MediaQuery screen="desktop">
        <Desktop
          handleSubmit={handleSubmit}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          password={password}
          setPassword={setPassword}
          rePassword={rePassword}
          setRePassword={setRePassword}
          isInvalidPhoneNumber={isInvalidPhoneNumber}
          isLoading={isLoading}
        />
      </MediaQuery>
      <MediaQuery screen="mobile">
        <Mobile
          handleSubmit={handleSubmit}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          password={password}
          setPassword={setPassword}
          rePassword={rePassword}
          setRePassword={setRePassword}
          isInvalidPhoneNumber={isInvalidPhoneNumber}
          isLoading={isLoading}
        />
      </MediaQuery>
    </>
  );
};

export default Register;

import React from "react";
import { clsx } from "clsx";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetUser } from "stores/auth";
import { useAuth } from "hooks/useAuth";
import LogoLg from "assets/logos/youth/stemer_logo_lg.jpeg";
import LogoMd from "assets/logos/youth/stemmer_logo_md.jpeg";
import LogoSM from "assets/logos/youth/stemer_logo_sm.jpeg";
import LogoXs from "assets/logos/youth/stemer_logo_sm.jpeg";

import ResponsiveImage from "components/ResponsiveImage";
import Icon from "components/Icons";

const Navbar = ({ isLandingPage, variant = "default" }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();

  const handleLogout = () => {
    dispatch(resetUser());

    localStorage.removeItem("stemmer_token");
    window.location.reload();
  };

  return (
    <nav className="px-[10px]">
      <div
        className={clsx("rounded-lg py-4 flex sm:flex-row gap-2 md:gap-5 container", {
          "justify-center mt-3": variant === "dashboard",
          "justify-between": variant === "default",
        })}
      >
        <div
          className={clsx("relative flex items-center gap-5 justify-center ")}
        >
          <Link to={"/"}>
            <ResponsiveImage
              images={[
                {
                  src: LogoLg,
                  width: 300,
                  height: "auto",
                }, // Large image for 1440px and above
                {
                  src: LogoMd,
                  width: 290,
                  height: "auto",
                }, // Medium image for 1024px
                {
                  src: LogoMd,
                  width: 280,
                  height: "auto",
                }, // Small image for 768px
                {
                  src: LogoXs,
                  width: 130,
                  height: "auto",
                }, // Smaller image for 320px
                {
                  src: LogoSM,
                  width: 120,
                  height: "auto",
                }, // Smallest image as a fallback
              ]}
            />
          </Link>
          {variant === "dashboard" && (
        <div className="">
          <div className="flex gap-3 items-center">
            <Link to="/profile" className="mt-1">
              <div className="cursor-pointer text-white bg-st-primary inline-block p-[10px] rounded-[10px]">
                <Icon name="settings" />
              </div>
            </Link>

            {isAuthenticated && !isLandingPage ? (
              <button
                onClick={handleLogout}
                className="bg-st-yellow py-2 px-6 rounded-lg text-white whitespace-nowrap"
              >
                Log out
              </button>
            ) : (
              <Link to={"/login"}>
                <button className="bg-st-primary py-2 px-6 rounded-lg text-white whitespace-nowrap">
                  Sign In
                </button>
              </Link>
            )}
          </div>
        </div>
      )}
        </div>
        {variant === "default" && (
          <div>
            {isAuthenticated && !isLandingPage ? (
              <button
                onClick={handleLogout}
                className="bg-st-yellow py-2 px-6 rounded-lg text-white whitespace-nowrap"
              >
                Log out
              </button>
            ) : (
              <Link to={"/login"}>
                <button className="bg-st-primary py-2 px-6 rounded-lg text-white whitespace-nowrap">
                  Sign In
                </button>
              </Link>
            )}
          </div>
        )}
      </div>
     
    </nav>
  );
};

export default Navbar;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { resetUser, forget } from "stores/auth";
import MediaQuery from "components/MediaQuery";
import Desktop from "./desktop";
import Mobile from "./mobile";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const { token, isLogin, errors } = useSelector((state) => state.authStore);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isEmailTouched, setIsEmailTouched] = useState(false);

  const validateEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };

  useEffect(() => {
    dispatch(resetUser());
  }, []);

  useEffect(() => {
    if (isLogin && token) {
      localStorage.setItem("stemmer_token", token);
      window.location.href = "/#/invite";
      window.location.reload();
    }
    // if (errors && errors.login) {
    //   console.log(">> errors: ", errors);
    //   toast.error(errors.login);
    // }
    setIsLoading(false);
  }, [isLogin, errors, token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsEmailTouched(true);

    if (!validateEmail(email)) {
      setIsInvalidEmail(true);
      return;
    }

    setIsLoading(true);
    dispatch(
      forget({
        userId: email,
        setIsLoading,
      })
    );
  };

  return (
    <>
      <MediaQuery screen="desktop">
        <Desktop
          handleSubmit={handleSubmit}
          email={email}
          setEmail={setEmail}
          isInvalidEmail={isInvalidEmail}
          setIsInvalidEmail={setIsInvalidEmail}
          isEmailTouched={isEmailTouched}
          isLoading={isLoading}
          validateEmail={validateEmail}
        />
      </MediaQuery>
      <MediaQuery screen="mobile">
        <Mobile
          handleSubmit={handleSubmit}
          email={email}
          setEmail={setEmail}
          isInvalidEmail={isInvalidEmail}
          setIsInvalidEmail={setIsInvalidEmail}
          isEmailTouched={isEmailTouched}
          isLoading={isLoading}
          validateEmail={validateEmail}
        />
      </MediaQuery>
    </>
  );
};

export default ForgetPassword;

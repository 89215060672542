import { useState, useEffect } from 'react';
import Iphone from "assets/images/iphone-15.webp";
import Icon from "components/Icons";
import PRIB01 from "assets/images/img-01.jpg";
import PRIB02 from "assets/images/img-02.jpg";
import PRIB03 from "assets/images/img-03.jpg";
import PRIB04 from "assets/images/img-04.jpg";
import PRIB05 from "assets/images/img-05.jpg";

import MobPRIB01 from "assets/images/mob-01.jpg";
import MobPRIB02 from "assets/images/mob-02.jpg";
import MobPRIB03 from "assets/images/mob-03.jpg";
import MobPRIB04 from "assets/images/img-01.jpg";
import MobPRIB05 from "assets/images/mob-05.jpg";

const images = [PRIB01, PRIB02, PRIB03, PRIB04, PRIB05]

const Mobileimages = [MobPRIB01, MobPRIB02, MobPRIB03, MobPRIB04, MobPRIB05]

const PreRegisterSection = () => {
  
  const [imageUrl, setImageUrl] = useState('')
  useEffect(() => {
    const number = Math.floor(Math.random() * 5) + 1;
    setImageUrl(images[number-1]);
  }, []);
  
  const [mobImageUrl, setMobImageUrl] = useState('')
  useEffect(() => {
    const number = Math.floor(Math.random() * 5) + 1;
    setMobImageUrl(Mobileimages[number-1]);
  }, []);

  return (
    <section className="container">
      <div className="relative">
      <img
              src={imageUrl}
              alt="Parent Register Inner Banner"
              width={0}
              height={0}
              sizes="100%"
              className="w-full h-full z-[-1] absolute bg-cover hidden xl:block rounded-2xl"
            />
        <div className="heading-font min-h-[434px] flex flex-col justify-start xl:items-start items-center shadow-1 overflow-hidden rounded-2xl border p-10 py-14">
          <h2 className="text-dark-blue font-bold text-3xl mb-3 text-center">
            Trade stocks like a pro using AI and machine learning
          </h2>
          {/* <p className="text-dark-blue font-bold text-2xl my-5">Free Trading Account</p> */}
          <div className="z-10 w-80vw h-auto sm:w-[100%] sm:h-[320px]">
            <img
                src={mobImageUrl}
                alt="Parent Register Inner Banner"
                width={0}
                height={0}
                sizes="100%"
                className="w-full h-full mt-4 xl:hidden rounded-2xl"
              />
          </div>
        </div>
        <div className="hidden absolute -top-[88px] right-20 xl:block">
          <img
            src={Iphone}
            alt="Iphone 15"
            sizes="100%"
            className="h-[504px] z-10"
          />
        </div>
      </div>
    </section>
  );
};

export default PreRegisterSection;

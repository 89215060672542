import NetworkPeople from "assets/images/network-people.webp";
import MobileLogo from "assets/logos/youth/stemer_logo_lg.jpeg";
import Mobile2Columns from "assets/logos/youth/stemer_logo_sm.jpeg";
import FormInput from "components/FormInput";
import { useLayoutEffect } from "react";
import MobileLayout from "components/layouts/MobileLayout";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";

const Mobile = ({
  onSubmit,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  isLoading,
  isInvalidEmail,
}) => {

  useLayoutEffect(() => {
    var htmlInput = document.getElementById("emailAddress");
  
    htmlInput.oninvalid = function (e) {
      e.target.setCustomValidity('"Email" is required');
    };
  
    htmlInput.oninput = function (e) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailValue = e.target.value;
  
      if (!emailRegex.test(emailValue)) {
        e.target.setCustomValidity("Please enter a valid email address");
      } else {
        e.target.setCustomValidity(''); // Clear custom validity if valid
      }
    };


  }, []);

  return (
    <MobileLayout imgSrcUrl={NetworkPeople} title="">
      <form
        className="flex-1 flex flex-col justify-between h-full max-w-[540px] mx-auto"
        onSubmit={onSubmit}
      >
        <div className="flex flex-col items-center justify-center gap-3">
          <Link to="/">
            <div className="relative flex items-center justify-center">
              <img
                src={MobileLogo}
                alt="Logo"
                sizes="100%"
                className="w-full max-[280px] hidden h-[36px]"
                priority="true"
              />
              <img
                src={Mobile2Columns}
                alt="Logo"
                sizes="100%"
                className="min-[280px] block h-[60px]"
                priority="true"
              />
            </div>
          </Link>
          <div className="max-w-[80%] w-full font-bold text-center text-st-gray-text">
            The best stock trading app
          </div>
        </div>
        <div className="mt-[22px]">
          <div>
            <FormInput
              screen="mobile"
              label="First Name"
              placeholder="Enter your first name"
              value={firstName}
              required
              minLength={3}
              maxLength={50}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="mt-[22px]">
            <FormInput
              screen="mobile"
              label="Last Name"
              placeholder="Enter your last name"
              value={lastName}
              required
              minLength={3}
              maxLength={50}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="mt-[22px]">
          <FormInput
              screen="mobile"
              label="Email Address"
              type="email"
              icon="mail"
              id="emailAddress"
              placeholder="Enter your email address"
              value={email}
              required
              isInvalid={isInvalidEmail}
              invalidMessage="Invalid Email Address or Phone Number"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {/* <FormInput
              screen="mobile"
              label="Email Address"
              type="email"
              icon="mail"
              placeholder="Enter your email address"
              value={email}
              required
              isInvalid={isInvalidEmail}
              invalidMessage="Invalid Email Address or Phone Number"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            /> */}
          </div>
          <div className="mt-[22px]">
            <FormInput
              screen="mobile"
              type="password"
              label="Password"
              icon="password"
              placeholder="Enter password"
              value={password}
              required
              minLength={8}
              maxLength={100}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mt-[22px]">
            <FormInput
              type="password"
              icon="password"
              screen="mobile"
              label="Confirm Password"
              placeholder="Confirm password"
              value={confirmPassword}
              required
              minLength={8}
              maxLength={100}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="flex-1 mt-[32px] pb-7">
          <button
            disabled={isLoading}
            className="w-full bg-st-primary text-white text-base font-semibold rounded-lg p-[14px] flex justify-center"
            type="submit"
          >
            {isLoading ? (
              <RotatingLines
                visible={true}
                height="24"
                width="24"
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
              />
            ) : (
              "Sign Up"
            )}
          </button>
        </div>
      </form>
    </MobileLayout>
  );
};

export default Mobile;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { toast } from "react-toastify";

import { login } from "stores/auth";
import MediaQuery from "components/MediaQuery";
import Desktop from "./desktop";
import Mobile from "./mobile";

const NewLogin = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const { token, isLogin, errors } = useSelector((state) => state.authStore);

  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  const validateEmail = (email) => {
    const pattern =
      /^(?:\+1[-.\s]?)?(?:\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$|^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    return pattern.test(email);
  };

  useEffect(() => {
    if (isLogin && token) {
      localStorage.setItem("stemmer_token", token);
      window.location.reload();
    }
    if (errors && errors.login) {
      toast.error("Invalid email or password. Please try again.", "Error");
    }
    if (errors && errors.login) {
      console.log(">> errors: ", errors);
      NotificationManager.error(errors.login);
    }
    setIsLoading(false);
  }, [isLogin, errors, token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsEmailTouched(true);
    // Log the values to the console
    setIsInvalidEmail("");

    // Validate the phone number or email
    if (!phoneNumber) {
      setIsInvalidEmail("Email or Phone Number is required");
      return;
    }
    
    if (!validateEmail(phoneNumber)) {
      setIsInvalidEmail("Invalid Email Address or Phone Number");
      return;
    }

    if (!validateEmail(phoneNumber)) {
      setIsInvalidEmail(true);
      return;
    }

    setIsLoading(true);
    dispatch(
      login({
        userId: phoneNumber,
        passcode: password,
      })
    );
  };
  return (
    <>
      <MediaQuery screen="desktop">
        <Desktop
          handleSubmit={handleSubmit}
          password={password}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setPassword={setPassword}
          isLoading={isLoading}
          isEmailTouched={isEmailTouched}
          isInvalidEmail={isInvalidEmail}
          setIsInvalidEmail={setIsInvalidEmail}
          setIsEmailTouched={setIsEmailTouched}
          validateEmail={validateEmail}
        />
      </MediaQuery>
      <MediaQuery screen="mobile">
        <Mobile
          handleSubmit={handleSubmit}
          password={password}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setPassword={setPassword}
          isLoading={isLoading}
          isEmailTouched={isEmailTouched}
          isInvalidEmail={isInvalidEmail}
          setIsInvalidEmail={setIsInvalidEmail}
          setIsEmailTouched={setIsEmailTouched}
          validateEmail={validateEmail}
        />
      </MediaQuery>
    </>
  );
};

export default NewLogin;

import React from "react";
import PropTypes from "prop-types";
import useMediaQuery from "react-use-media-query-hook";

const ResponsiveImage = ({ images }) => {
  const isAbove1440 = useMediaQuery("(min-width: 1440px)");
  const isBetween1024And1440 = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1439px)"
  );
  const isBetween768And1024 = useMediaQuery(
    "(min-width: 768px) and (max-width: 1023px)"
  );
  const isBetween320And768 = useMediaQuery(
    "(min-width: 320px) and (max-width: 767px)"
  );
  const isBelow320 = useMediaQuery("(max-width: 319px)");

  if (!images || images.length < 5) {
    console.error(
      "Please provide at least 5 images with src, width, and height."
    );
    return null;
  }

  let imageSrc, imageWidth, imageHeight;

  if (isAbove1440) {
    ({ src: imageSrc, width: imageWidth, height: imageHeight } = images[0]);
  } else if (isBetween1024And1440) {
    ({ src: imageSrc, width: imageWidth, height: imageHeight } = images[1]);
  } else if (isBetween768And1024) {
    ({ src: imageSrc, width: imageWidth, height: imageHeight } = images[2]);
  } else if (isBetween320And768) {
    ({ src: imageSrc, width: imageWidth, height: imageHeight } = images[3]);
  } else if (isBelow320) {
    ({ src: imageSrc, width: imageWidth, height: imageHeight } = images[4]);
  }

  return (
    <div style={{ width: imageWidth, height: imageHeight }}>
      <img
        src={imageSrc}
        width={"100%"}
        height={"100%"}
        alt="Responsive logo"
      />
    </div>
  )
}

ResponsiveImage.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default ResponsiveImage;

import Register from "pages/register";

import { HashRouter, Routes, Route, useLocation } from "react-router-dom";
import NewLogin from "pages/newlogin";
import NewProfile from "pages/newprofile";
import SignUp from "pages/signup";
import Teen from "pages/teen";
import Invite from "pages/invite";
import ForgetPassword from "pages/forget-password";
import HelpCenter from "pages/help-center";
import SecureLink from "pages/secure-link";
import Waitlist from "pages/Waitlist";

import PrivateRoute from "components/routes/PrivateRoute";
import PublicRoute from "components/routes/PublicRoute";
import { useAuth } from "hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getWaitlistPosition } from "stores/auth";
import { RotatingLines } from "react-loader-spinner";

export const ROUTES = {
  protected: {
    PROFILE: "/profile",
    INVITE: "/invite",
  },
  public: {
    REGISTER: "/register",
    LOGIN: "/login",
    SIGNUP: "/signup",
    HELP: "/help",
    FORGOT: "/forgot",
    INVITATION: "invitation/:id",
  },
  duplex: {
    RESET_PASSWORD: "/resetpassword/activate",
  },
  root: "/",
};

function App() {
  const dispatch = useDispatch();
  const waitlist = useSelector((state) => state.authStore.waitlist);
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const protectedPaths = Object.keys(ROUTES.protected).map(
      (x) => ROUTES.protected[x]
    );
    const isProtected = protectedPaths.find((x) => x === location.pathname);

    if (isAuthenticated && isProtected) {
      dispatch(getWaitlistPosition());
    }
  }, [dispatch, isAuthenticated, location.pathname]);

  if (waitlist?.isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <RotatingLines
          strokeColor="#7C7DF1"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    );
  }

  return (
    <Routes>
      <Route path={ROUTES.duplex.RESET_PASSWORD} element={<SecureLink />} />
      <Route
        path={ROUTES.public.INVITATION}
        element={<PublicRoute component={Register} />}
      />
      <Route
        path={ROUTES.public.REGISTER}
        element={<PublicRoute component={Register} />}
      />
      <Route
        path={ROUTES.protected.PROFILE}
        element={<PrivateRoute component={NewProfile} />}
      />
      <Route
        path={ROUTES.protected.INVITE}
        element={<PrivateRoute component={Invite} />}
      />
      <Route
        path={ROUTES.public.LOGIN}
        element={<PublicRoute component={NewLogin} />}
      />
      <Route
        path={ROUTES.public.SIGNUP}
        element={<PublicRoute component={SignUp} />}
      />
      <Route
        path={ROUTES.public.FORGOT}
        element={<PublicRoute component={ForgetPassword} />}
      />
      <Route path={ROUTES.public.HELP} element={<HelpCenter />} />
      <Route path={ROUTES.root} element={<Teen />} />
    </Routes>
  );
}

export default App;

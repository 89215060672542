import axios from "axios";

const baseURL = "https://youth.stemmer-markets.ai/";

const API = axios.create({
  baseURL,
  headers: {
    Authorization: `${
      localStorage.getItem("stemmer_token")
        ? localStorage.getItem("stemmer_token")
        : "f5d7a1f7-4c13-4d67-9813-ee6d6e76a135"
    }`,
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});
export default API;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { register } from "stores/auth";
import MediaQuery from "components/MediaQuery";
import Desktop from "./desktop";
import Mobile from "./mobile";

const SignUp = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { token, isRegister, errors } = useSelector((state) => state.authStore);

  useEffect(() => {
    if (isRegister) {
      localStorage.setItem("stemmer_token", token);
      window.location.reload();
    }
    if (!isRegister && errors && errors.register) {
      NotificationManager.error(
        errors.register || "Signup failed!",
        errors.name || "Signup Error"
      );
    }
    setIsLoading(false);
  }, [isRegister, errors, token]);

  const onSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page

    setIsLoading(true);
    if (password !== confirmPassword) {
      NotificationManager.error(
        '"Password" and "Confirm Password" don\'t match',
        "Validation Error",
        3000
      );
      return setIsLoading(false);
    }

    dispatch(
      register({
        userId: email,
        firstName: firstName,
        lastName: lastName,
        email: email,
        passcode: password,
        phoneNumber: location.state?.phone || "",
      })
    );
  };

  return (
    <>
      <MediaQuery screen="desktop">
        <Desktop
          onSubmit={onSubmit}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          isLoading={isLoading}
        />
      </MediaQuery>
      <MediaQuery screen="mobile">
        <Mobile
          onSubmit={onSubmit}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          isLoading={isLoading}
        />
      </MediaQuery>
    </>
  );
};

export default SignUp;

import { useCallback, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import Modal from "components/Modal/mobile";
import ShareLink from "components/ShareLink";
import Icon from "components/Icons";

const InviteModal = ({ open, setIsOpen, inviteLink }) => {
  const [copied, copyToClipboard] = useCopyToClipboard();

  const handleCopyClick = useCallback(
    (text) => {
      copyToClipboard(text);
    },
    [copyToClipboard]
  );

  useEffect(() => {
    if (copied) {
      NotificationManager.success("Link copied to the clipboard!", "Success");
    }
  }, [copied]);
  return (
    <Modal open={open} onClose={() => setIsOpen(false)}>
      <h3 className="text-lg font-bold pb-4">Share this link via</h3>
      <ShareLink shareUrl={inviteLink} />
      <h5 className="text-st-gray-text text-sm pt-4 font-medium">
        Or copy link
      </h5>
      <div className="mt-[10px] max-w-full w-full overflow-hidden border rounded flex items-center justify-between">
        <div className="px-[12px]">
          <Icon name="link" />
        </div>
        <div className="py-[14px] w-[80%] overflow-x-auto no-scroll">
          <div className="flex">
            <span className="whitespace-nowrap">{inviteLink}</span>
          </div>
        </div>
        <button
          onClick={() => handleCopyClick(inviteLink)}
          className="bg-st-primary py-[10px] mr-[4px] text-white px-[14px] rounded"
        >
          Copy
        </button>
      </div>
    </Modal>
  );
};

export default InviteModal;

import MobileLogo from "assets/logos/youth/stemer_logo_lg.jpeg";
import MobilestLogo from "assets/logos/youth/stemer_logo_sm.jpeg";
import FormInput from "components/FormInput";
import MobileLayout from "components/layouts/MobileLayout";
import { useLayoutEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { formatToUSNumber } from "utils/format-to-us-number";
import { isMobileNumber } from "utils/is-mobile-number";
import { Link } from "react-router-dom";

const Mobile = ({
  handleSubmit,
  isLoading = false,
  phoneNumber,
  setPhoneNumber,
  password,
  setPassword,
  isInvalidEmail,
  setIsInvalidEmail,
  isEmailTouched,
  validateEmail,
}) => {
  const [isMobileNo, setIsMobileNo] = useState(false);

  useLayoutEffect(() => {
    const htmlInput = document.getElementById("phoneNumber");

    const handleInvalid = (e) => {
      e.target.setCustomValidity('"Email" or "Phone number" is required');
    };

    const handleInput = (e) => {
      e.target.setCustomValidity('');
    };

    htmlInput.addEventListener('invalid', handleInvalid);
    htmlInput.addEventListener('input', handleInput);

    return () => {
      htmlInput.removeEventListener('invalid', handleInvalid);
      htmlInput.removeEventListener('input', handleInput);
    };
  }, []);


  return (
    <MobileLayout title="">
      <div className="flex flex-col items-center justify-center gap-3">
        <Link to={"/"}>
          <div className="relative flex items-center justify-center">
            <img
              src={MobileLogo}
              alt="Logo"
              sizes="100%"
              className="w-full max-[280px]:hidden hidden h-[36px]"
              priority="true"
            />
            <img
              src={MobilestLogo}
              alt="Logo"
              sizes="100%"
              className="block h-[60px]"
              priority="true"
            />
          </div>
        </Link>
        <div className="max-w-[80%] w-full text-center text-st-gray-text"></div>
        <h1 className="text-base text-red-600 text-center font-semibold mt-4 text-[30px]">{isInvalidEmail ? isInvalidEmail : ""}</h1>

      </div>
      <form
        className="flex flex-col h-full max-w-[540px] mx-auto mt-3"
        onSubmit={handleSubmit}
      >
        <div>
          <div>
            <FormInput
              screen="mobile"
              label="Email or Phone"
              placeholder="Enter your email address or phone number"
              value={phoneNumber}
              required
              type="text"
              id="phoneNumber"
              maxLength={isMobileNo ? 17 : 100}
              invalidMessage="Invalid Email Address or Phone Number"
              onChange={(e) => {
                const isValid = validateEmail(e.target.value);
                if (!isValid && isEmailTouched) {
                  setIsInvalidEmail(true);
                } else {
                  setIsInvalidEmail(false);
                }
                const value = e?.target?.value
                  ?.replace("+1", "")
                  .replace(")", "")
                  .replace("(", "")
                  .replace(" ", "")
                  .replace("-", "");

                const isMobile = isMobileNumber(value) && value.length >= 2;
                if (isMobile) {
                  setIsMobileNo(true);
                } else {
                  setIsMobileNo(false);
                }
                if (isMobile) {
                  setPhoneNumber(formatToUSNumber(value));
                } else {
                  setPhoneNumber(value);
                }
              }}
            />
          </div>
          <div className="mt-[22px]">
            <FormInput
              screen="mobile"
              type="password"
              label="Password"
              icon="password"
              placeholder="Enter password"
              value={password}
              required
              minLength={8}
              maxLength={50}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex justify-between mt-[19px]">
            <div>
              <p className="text-st-primary text-xs w-max">
                <Link to="/forgot">Forgot Password?</Link>
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 flex items-start mt-[32px]">
          <button
            disabled={isLoading}
            className="w-full bg-st-primary text-white text-sm font-semibold rounded-lg p-[14px] flex justify-center"
            type="submit"
          >
            {isLoading ? (
              <RotatingLines
                visible={true}
                height="24"
                width="24"
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
              />
            ) : (
              "Sign in"
            )}
          </button>
        </div>
      </form>
    </MobileLayout>
  );
};

export default Mobile;

import { getLinkInvitePost } from "stores/auth";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";
import { getContacts } from "stores/contact";
import MediaQuery from "components/MediaQuery";
import Desktop from "./desktop";
import Mobile from "./mobile";

const Invite = () => {
  const dispatch = useDispatch();
  const { contacts } = useSelector((state) => state.contactStore);
  const waitlist = useSelector((state) => state.authStore.waitlist);

  const [inviteLink, setInviteLink] = useState("");
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInviteButtonClick = useCallback(async () => {
    setIsLoading(true);

    try {
      const actionResult = await dispatch(getLinkInvitePost({ uriInd: 'y' }));
      const payload = unwrapResult(actionResult);

      setInviteLink(payload);
      setOpenInviteModal(true);
      setIsLoading(false);
    } catch (error) {
      if (error?.message) {
        setIsLoading(false);
        return NotificationManager.error("Unable to get invite link!", "Error");
      }
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  return (
    <>
      <MediaQuery screen="desktop">
        <Desktop
          contacts={contacts}
          handleInviteButtonClick={handleInviteButtonClick}
          openInviteModal={openInviteModal}
          setOpenInviteModal={setOpenInviteModal}
          inviteLink={inviteLink}
          isLoading={isLoading}
          waitlist={waitlist.data}
        />
      </MediaQuery>
      <MediaQuery screen="mobile">
        <Mobile
          contacts={contacts}
          handleInviteButtonClick={handleInviteButtonClick}
          openInviteModal={openInviteModal}
          setOpenInviteModal={setOpenInviteModal}
          inviteLink={inviteLink}
          isLoading={isLoading}
          waitlist={waitlist.data}
        />
      </MediaQuery>
    </>
  );
};

export default Invite;

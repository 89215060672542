import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "assets/logos/youth/stemer_logo_lg.jpeg";
import FormInput from "components/FormInput";
import MainLayout from "components/layouts/MainLayout";
import { RotatingLines } from "react-loader-spinner";
import { formatToUSNumber } from "utils/format-to-us-number";

const Desktop = ({
  handleSubmit,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  password,
  setPassword,
  rePassword,
  setRePassword,
  isLoading,
}) => {
  useLayoutEffect(() => {
    var htmlInputPhone = document.getElementById("phoneNumber");
    var htmlInputEmail = document.getElementById("emailAddress");
    htmlInputEmail.oninvalid = function (e) {
      e.target.setCustomValidity('"Email" is required');
    };
    htmlInputPhone.oninvalid = function (e) {
      e.target.setCustomValidity('"Phone number" is required');
    };
  }, []);

  return (
    <MainLayout>
      <div className="bg-st-primary sm:bg-white sm:px-10 w-full">
        <div className="flex flex-col items-center justify-center gap-3">
          <div className="relative flex items-center justify-center">
            <Link to="/">
              <img
                src={Logo}
                alt="Logo"
                width="0"
                height="0"
                sizes="100%"
                className="w-full h-[18px]"
                priority="true"
              />
            </Link>
          </div>
          <div className="max-w-[80%] w-full font-bold text-center text-st-gray-text">
          The best stock trading app
          </div>
        </div>
        <form
          className="sm:mt-10 p-4 sm:p-0 rounded-t-3xl sm:rounded-none flex flex-col justify-between"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-4">
            <FormInput
              label="First Name"
              placeholder="Enter your first name"
              value={firstName}
              required
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <FormInput
              label="Last Name"
              placeholder="Enter your last name"
              value={lastName}
              required
              type="text"
              onChange={(e) => setLastName(e.target.value)}
            />
            <FormInput
              label="Email"
              placeholder="Enter your email address"
              value={email}
              required
              type="text"
              id="emailAddress"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <FormInput
              label="Phone Number"
              placeholder="Enter your phone number"
              value={phoneNumber}
              required
              type="text"
              id="phoneNumber"
              maxLength={17}
              onChange={(e) => {
                const value = e?.target?.value
                  ?.replace("+1", "")
                  .replace(")", "")
                  .replace("(", "")
                  .replace(" ", "")
                  .replace("-", "");

                setPhoneNumber(formatToUSNumber(value));
              }}
            />
            <FormInput
              type="password"
              label="Password"
              placeholder="Enter password"
              value={password}
              required
              minLength={8}
              maxLength={50}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormInput
              type="password"
              label="Confirm Password"
              placeholder="Enter password again"
              value={rePassword}
              required
              minLength={8}
              maxLength={50}
              onChange={(e) => setRePassword(e.target.value)}
            />
          </div>
          <div className="mt-[10vh]">
            <button
              disabled={isLoading}
              className="w-full bg-st-primary text-white rounded-lg p-4 flex justify-center"
              type="submit"
            >
              {isLoading ? (
                <RotatingLines
                  visible={true}
                  height="24"
                  width="24"
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                />
              ) : (
                "Register"
              )}
            </button>
          </div>
          <div className="mt-7 text-center">
            <p className="text-xs w-max mx-auto">
              Already have an account?{" "}
              <Link to="/login">
                <span className="text-st-primary">Login</span>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default Desktop;

import TeenBannerSection from "components/teen/TeenBannerSection";
import TeenPreRegisterSection from "components/teen/TeenPreRegisterSection";
import TeenRegisterBannerSection from "components/teen/TeenRegisterBannerSection";
import Footer from "components/Footer";

const Teen = () => {
  return (
    <main>
      <TeenBannerSection />
      <div className="mx-auto max-w-[1313px] w-full px-[18px] sm:px-[25px] mt-[64px]  xl:mt-[144px]">
        <TeenPreRegisterSection />
      </div>
      <div className="max-w-[1440px] mx-auto">
        <div className="mx-[10px]">
          <TeenRegisterBannerSection />
        </div>
      </div>
      <Footer hideFooterOnMobile={false} />
    </main>
  );
};

export default Teen;

import TeenRegisterBanner from "assets/images/teen-register-banner.webp";
import ParentRegisterInnerBanner from "assets/images/parent-register-inner-banner.webp";

const RegisterBannerSection = () => {
  return (
    <a
      rel="noreferrer"
      href="https://www.stemmer-markets.ai/"
      target="_blank"
      style={{ position: "relative", overflow: "hidden" }}
    >
      {/* <section className="mx-[10px] mt-[82px] h-full lg:h-[600px] lg:max-h-[600px] bg-st-primary rounded-md">
        <div className="relative h-full flex flex-col lg:flex-row justify-center items-center lg:justify-between lg:items-start p-14">
          <img
          src={TeenRegisterBanner}
          alt="Parent Register Banner"
          width={0}
          height={0}
          className="w-full h-[600px] rounded-lg overflow-hidden bg-[rgb(27, 20, 41)]"
        />
          <div className="text-white text-shadow-lg self-start w-full z-40 lg:mb-auto mb-6 lg:max-w-[45%] flex flex-col justify-center">
            <p className="font-bold text-2xl sm:text-3xl">
              Parents/guardians/aunts/ uncles/
              <br />
              grand parents pre-register here
            </p>
            <p className="h-[3px] bg-st-primary w-[289px] my-5 max-w-[40%]">
              &nbsp;
            </p>
          </div>
          <div className="w-full sm:w-1/2 flex items-center justify-center">
            <div className="z-10 w-80vw h-auto lg:mr-16 mr-0 sm:w-[481px] sm:h-[502px]">
              <img
                src={ParentRegisterInnerBanner}
                alt="Parent Register Inner Banner"
                width={0}
                height={0}
                sizes="100%"
                className="w-full h-full z-10"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="absolute top-0 left-0 right-0 bottom-0">
        <img
          src={TeenRegisterBanner}
          alt="Parent Register Inner Banner"
          width={0}
          height={0}
          sizes="100%"
          className="w-full h-full z-10 rounded-md"
        />
      </div> */}
      <section className="mt-[82px] mx-[25px] max-h-[600px] relative">
        <div className="rounded-lg overflow-hidden">
          <img
            src={TeenRegisterBanner}
            alt="Teen Register Banner"
            width={"100%"}
            height={"100%"}
            className="w-full h-[600px]"
          />
          <div className="absolute top-[10%] md:top-[28%] md:left-[79px] left-[30px] text-white max-w-[65vw] lg:max-w-[600px] z-10">
            <p className="font-bold text-2xl sm:text-3xl break-words">
            Parents, Friends & Family Members 
            <br />pre-register here
            </p>
            <p className="h-[3px] bg-st-primary w-[289px] my-5 max-w-[40%]">
              &nbsp;
            </p>
          </div>
        </div>
      </section>
    </a>
  )
}

export default RegisterBannerSection;

// import TeenRegisterBanner from "assets/images/teen-register-banner.webp";
// import ParentRegisterInnerBanner from "assets/images/parent-register-inner-banner.webp";

// const TeenRegisterBannerSection = () => {
//   return (
//     <a target="_blank" rel="noreferrer" href="https://beta.stemmermarkets.com/">
//       <section className="mt-[82px] max-h-[600px] relative">
//         <div className="rounded-lg overflow-hidden">
//           <img
//             src={TeenRegisterBanner}
//             alt="Teen Register Banner"
//             width={0}
//             height={0}
//             className="w-full h-[600px]"
//           />
// <div className="absolute top-[10%] md:top-[28%] md:left-[79px] left-[30px] text-white max-w-[70vw] lg:max-w-[600px] z-10">
//   <p className="font-bold text-2xl sm:text-3xl">
//     Parents/guardians/aunts/ uncles/
//     <br />
//     grand parents pre-register here
//   </p>
//   <p className="h-[3px] bg-st-primary w-[289px] my-5 max-w-[40%]">
//     &nbsp;
//   </p>
//   <p className="sm:text-lg">
//     Lorem Ipsum is simply dummy text of the printing and typesetting
//     industry. Lorem Ipsum has been the industry's standard dummy text
//     ever since the 1500s, when an unknown printer took a galley of
//     type and scrambled it to make a type specimen book. It has
//     survived not only five centuries
//   </p>
// </div>
<div className="z-10 w-80vw h-auto lg:mr-16 mr-0 sm:w-[481px] sm:h-[502px]">
  <img
    src={ParentRegisterInnerBanner}
    alt="Parent Register Inner Banner"
    width={0}
    height={0}
    sizes="100%"
    className="w-full h-full z-10"
  />
</div>;
//         </div>
//       </section>
//     </a>
//   );
// };

// export default TeenRegisterBannerSection;

import { HashRouter } from "react-router-dom";
import Routes from "components/routes";
import ScrollToTop from "components/ScrollToTop";

function App() {
  return (
    <HashRouter>
      <ScrollToTop />
      <Routes />
    </HashRouter>
  );
}

export default App;

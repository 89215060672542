import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, resetUser, updateUser, uploadAvatar } from "stores/auth";
import { NotificationManager } from "react-notifications";
import MediaQuery from "components/MediaQuery";
import Desktop from "./desktop";
import Mobile from "./mobile";

const NewProfile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authStore);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [email, setEmail] = useState("");

  const [file, setFile] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const inputFileRef = useRef(null);

  const handleClick = () => {
    if (inputFileRef.current) {
      console.log("here", inputFileRef.current);
      inputFileRef.current.click();
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      const imgURL = await toBase64(selectedFile);
      setAvatar(imgURL);
      setFile(selectedFile);
      console.log("Selected file:", selectedFile);
    }
  };

  const handleSubmitForm = useCallback(
    async (e) => {
      e.preventDefault();

      setIsSubmitting(true);
      console.log("Form submitted with values:", {
        firstName,
        lastName,
        dateOfBirth,
        email,
      });

      const fd = new FormData();

      if (file) {
        fd.append("upload", file);
        fd.append("memberOid", user?.memberOidStr);

        await dispatch(uploadAvatar(fd));
      }

      await dispatch(
        updateUser({
          firstName: firstName,
          lastName: lastName,
          dateOfBirth,
          email1: email,
        })
      );

      setIsSubmitting(false);
      NotificationManager.success(
        "Profile is successfully updated!",
        "Success"
      );
    },
    [
      dispatch,
      email,
      file,
      firstName,
      lastName,
      dateOfBirth,
      user?.memberOidStr,
    ]
  );

  useEffect(() => {
    dispatch(resetUser());
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setDateOfBirth(user.dateOfBirth);
      setEmail(user.email1);

      if (user.profilePictureURL) {
        setAvatar(user.profilePictureURL);
      }
    }
  }, [user]);

  return (
    <>
      <MediaQuery screen="desktop">
        <Desktop
          firstName={firstName}
          lastName={lastName}
          dateOfBirth={dateOfBirth}
          email={email}
          avatar={avatar}
          handleFileChange={handleFileChange}
          handleClick={handleClick}
          inputFileRef={inputFileRef}
          handleSubmitForm={handleSubmitForm}
          isSubmitting={isSubmitting}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setDateOfBirth={setDateOfBirth}
          setEmail={setEmail}
          user={user}
        />
      </MediaQuery>
      <MediaQuery screen="mobile">
        <Mobile
          firstName={firstName}
          lastName={lastName}
          dateOfBirth={dateOfBirth}
          email={email}
          avatar={avatar}
          handleFileChange={handleFileChange}
          handleClick={handleClick}
          inputFileRef={inputFileRef}
          handleSubmitForm={handleSubmitForm}
          isSubmitting={isSubmitting}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setDateOfBirth={setDateOfBirth}
          setEmail={setEmail}
          user={user}
        />
      </MediaQuery>
    </>
  );
};

export default NewProfile;

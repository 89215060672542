import "./index.scss";
import { Link } from "react-router-dom";
import LoginBanner from "assets/images/banner.webp";

import Footer from "components/Footer";

const MainLayout = ({ children, showFooter = false }) => {
  return (
    <main className="relative pt-[70px]">
      <div className="flex flex-1 max-w-[1200px] mx-auto">
        <div className="flex-1 h-[949px] w-full hidden">
          <Link to={"/"}>
            <img
              className="w-full h-full rounded-lg"
              src={LoginBanner}
              alt="Login Banner"
            />
          </Link>
        </div>
        <div className="flex-1 flex max-w-[640px] mx-auto">{children}</div>
      </div>
      {showFooter && <Footer />}
    </main>
  );
};

export default MainLayout;
